import { Injectable } from '@angular/core';
import { DigitalDocsService } from 'src/app/services/digital-docs.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUsuarioVO } from '../models/usuario-vo';
import { Observable } from 'rxjs';
import { IUsuarioDTO } from '../models/usuario-dto';
import { IUsuarioEditarVO } from '../models/usuario-editar-vo';
import { map } from 'rxjs/operators';
import { ISolicitudRegistroEstudianteVO } from 'src/app/guest-components/models/ISolicitudRegistroEstudianteVO';
import { IUsuarioExternoVO } from '../models/usuario-externo-vo';
import { IDataSearchUsuariosVO } from '../models/data-search-usuarios-vo';
import { IUsuarioBuscadorDTO } from '../models/usuario-buscador-dto';
import { PaginatedResponse } from 'src/app/digital-core/models/paginatedResponse';
import { IMiUsuarioVO } from 'src/app/mi-usuario/models/mi-usuario-vo';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends DigitalDocsService {

  constructor(private http: HttpClient) {
    super('api/usuario', http);
  }

  public listaUsuarios(): Observable<IUsuarioDTO[]> {
    return this.http.get<any>(this.url).pipe(
      map(response => {
        return response.data.lista as IUsuarioDTO[];
      })
    );
  }

  public listaUsuariosNoConsejeros(): Observable<IUsuarioDTO[]> {
    return this.http.get<any>(this.url + '/consejero').pipe(
      map(response => {
        return response.data as IUsuarioDTO[];
      })
    );
  }

  public getListaUsuariosPaginado(page: number, nroE: number, cargo?: string): Observable<PaginatedResponse<IUsuarioDTO>> {
    let params = new HttpParams();
    params = params.append('cargo', this.cambiarNull(cargo));
    params = params.append('nroElementosPorPagina', this.cambiarNull(String(nroE)));
    params = params.append('nroPagina', this.cambiarNull(String(page)));
    return this.http.get<any>(this.url , { params }).pipe(
      map(response => {
        return response.data as PaginatedResponse<IUsuarioDTO>;
      })
    );
  }

  public listaUsuariosDisponiblesParaRegistarPerfil(data: IDataSearchUsuariosVO): Observable<IUsuarioBuscadorDTO[]> {
    let params = new HttpParams();
    params = params.append('nombre', this.cambiarNull(data.nombre));
    params = params.append('apellidoPaterno', this.cambiarNull(data.apellidoPaterno));
    params = params.append('apellidoMaterno', this.cambiarNull(data.apellidoMaterno));
    return this.http.get<any>(this.url + '/disponiblesperfil', {params}).pipe(
      map(response => {
        return response.data as IUsuarioBuscadorDTO[];
      })
    );
  }

  private cambiarNull(valor: string): string {
    return (valor === null || valor === undefined) ? '' : valor;
  }

  public filtrarUsuarios(cargo: String): Observable<IUsuarioDTO[]> {
    return this.http.get<any>(this.url + '/cargo/' + cargo).pipe(
      map(response => {
        return response.data as IUsuarioDTO[];
      })
    );
  }

  public getUsuarioById(id: string): Observable<IUsuarioDTO> {
    return this.http.get<any>(this.url + '/' + id).pipe(
      map(response => {
        return response.data as IUsuarioDTO;
      })
    );
  }

  public postUsuario(usuario: IUsuarioVO): Observable<any> {
    return this.http.post(this.url, usuario);
  }

  public postEstudiante(estudiante: ISolicitudRegistroEstudianteVO): Observable<any> {
    return this.http.post(this.url + '/estudiante', estudiante);
  }

  public editUsuario(usuario: IUsuarioEditarVO, id: string): Observable<any> {
    return this.http.put(this.url + '/' + id, usuario);
  }

  public habilitarInhabilitarUsuario(id: number): Observable<any> {
    return this.http.patch<any>(`${this.url}/${id}`,null).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  public postUsuarioExterno(data: IUsuarioExternoVO) {
    return this.http.post<any>(this.url + '/externo/perfil', data);
  }

  public editUsuarioContrasenia(usuario: IMiUsuarioVO, id: string): Observable<any> {
    return this.http.put(this.url + '/password/' + id, usuario);
  }
}
