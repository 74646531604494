import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from '../../services/usuario.service';
import { IUsuarioVO } from '../../models/usuario-vo';
import { Router } from '@angular/router';
import { CargoService } from '../../services/cargo.service';
import { ICargoUserDTO } from '../../models/cargo-user-dto';
@Component({
  selector: 'app-registro-usuario',
  templateUrl: './registro-usuario.component.html',
  styleUrls: ['./registro-usuario.component.scss']
})
export class RegistroUsuarioComponent implements OnInit, OnDestroy {
  public listaCargos: ICargoUserDTO[];
  public cargosSeleccionados: string[] = [];
  public idUsuario: string;
  public formUsuario: FormGroup;
  private subscriptions: Subscription = new Subscription();
  constructor(private fb: FormBuilder, private usuarioService: UsuarioService,
    private cargoService: CargoService,
    private router: Router,
    private toast: ToastrService) { }

  ngOnInit(): void {
    this.buildForm();
    this.loadDatos();
  }

  private loadDatos(): void {
    const subCargos$ = this.cargoService.getlistaCargos().subscribe(
      resp => {
        this.listaCargos = resp;
      });
    this.subscriptions.add(subCargos$);
  }

  buildForm(): void {
    this.formUsuario = this.fb.group({
      apellidoMaterno:  ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[A-Za-záéíóúÁÉÍÓÚñÑüÜ]+(\s[A-Za-záéíóúÁÉÍÓÚñÑüÜ]+)*$/)]],
      apellidoPaterno:  ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[A-Za-záéíóúÁÉÍÓÚñÑüÜ]+(\s[A-Za-záéíóúÁÉÍÓÚñÑüÜ]+)*$/)]],
      email:            ['', [Validators.required, Validators.email,Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
      nombres:          ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[A-Za-záéíóúÁÉÍÓÚñÑüÜ]+(\s[A-Za-záéíóúÁÉÍÓÚñÑüÜ]+)*$/)]],
      password:         ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d].{8,}')]],
      confirm_password: ['', Validators.required],
    }, {
      validator: [this.validarApellidos('apellidoPaterno', 'apellidoMaterno'), this.mustMatch('password', 'confirm_password')]
    });
  }

  onSubmit() {
    const usuarioEnviar: IUsuarioVO = {
      nombres: this.nombres.value,
      apellidoPaterno: this.apellidoPaterno.value,
      apellidoMaterno: this.apellidoMaterno.value,
      password: this.password.value,
      email: this.email.value,
      cargos: this.cargosSeleccionados,
    };
    this.registrarUsuario(usuarioEnviar);
  }

  onExit() {
    this.router.navigate(['/usuarios/lista']);
  }

  registrarUsuario(usuarioE: IUsuarioVO): void {
    const sub1$ = this.usuarioService.postUsuario(usuarioE).subscribe(resp => {
      this.toast.success(resp.message, '', {
        timeOut: 30000,
        closeButton: true,
        enableHtml: true,
      });
      this.loadDatos();
      this.formUsuario.reset();
    }, err => {
      for (const e of err.error.errors) {
        this.toast.error(e, '', {
          timeOut: 30000,
          closeButton: true,
          enableHtml: true,
        });
      }
    });
    this.subscriptions.add(sub1$);
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup): void => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  validarApellidos(apellidoP: string, apellidoM: string) {
    return (group: FormGroup): void => {
      const apellidoPControl = group.controls[apellidoP];
      const apellidoMControl = group.controls[apellidoM];
      if (apellidoPControl.value !== '' && apellidoMControl.value !== '') {
        return;
      }
      if (apellidoPControl.value !== '' && apellidoMControl.value === '') {
        apellidoMControl.setErrors({ required: false });
        apellidoMControl.setErrors(null);
      } else if (apellidoPControl.value === '' && apellidoMControl.value !== '') {
        apellidoPControl.setErrors({ required: false });
        apellidoPControl.setErrors(null);
      }
    };
  }

  agregarCargo(id:string,i:number): void {
    let element = <HTMLInputElement> document.getElementById("c"+i);
    let isChecked:boolean = element.checked;
    if(isChecked){
      this.agregarRol(id);
    }else{
      this.quitarRol(id);
    }

    /*if (this.cargosSeleccionados.length < 1) {
      this.cargosSeleccionados.push(id);
    } else {
      if (this.cargosSeleccionados.includes(id)) {
        this.cargosSeleccionados = this.cargosSeleccionados.filter((elem, i, a) => elem !== id);
      } else {
        this.cargosSeleccionados.push(id);
      }
    }*/
  }

  private agregarRol(id:string):void{
    if(!this.rolSeleccionado(id)){
      this.cargosSeleccionados.push(id);
    }
  }

  private rolSeleccionado(id:string):boolean{
    let bandera:boolean = false;
    this.cargosSeleccionados.forEach(element => {
      if(element==id){
        bandera = true;
      }
    });
    return bandera;
  }

  private quitarRol(id:string):void{
    let i:number = 0;
    while(i<this.cargosSeleccionados.length) {
      if(this.cargosSeleccionados[i]==id){
        this.cargosSeleccionados.splice(i,1)
      }
      i++;
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  get f() {
    return this.formUsuario.controls;
  }
  get nombres(): FormControl {
    return this.formUsuario.get('nombres') as FormControl;
  }
  get apellidoPaterno(): FormControl {
    return this.formUsuario.get('apellidoPaterno') as FormControl;
  }
  get apellidoMaterno(): FormControl {
    return this.formUsuario.get('apellidoMaterno') as FormControl;
  }
  get email(): FormControl {
    return this.formUsuario.get('email') as FormControl;
  }
  get password(): FormControl {
    return this.formUsuario.get('password') as FormControl;
  }
  get confirm_password(): FormControl {
    return this.formUsuario.get('confirm_password') as FormControl;
  }
}
