import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistroUsuarioComponent } from './components/registro-usuario/registro-usuario.component';
import { ListaUsuariosComponent } from './components/lista-usuarios/lista-usuarios.component';
import { MostrarUsuarioComponent } from './components/mostrar-usuario/mostrar-usuario.component';
import { EditarUsuarioComponent } from './components/editar-usuario/editar-usuario.component';
import { RolGuard } from '../autentificacion/guards/rol.guard';


const routes: Routes = [ 
  {
    path: 'mostrar/:id',
    component: MostrarUsuarioComponent,
    data : [
      { nombre : 'ADMINISTRADOR' },
    ],
    canActivate : [ RolGuard ] 
  },
  {
    path: 'registro',
    component: RegistroUsuarioComponent,
    data : [
      { nombre : 'ADMINISTRADOR' },
    ],
    canActivate : [ RolGuard ] 
  },
  {
    path: 'edicion/:id',
    component: EditarUsuarioComponent,
    data : [
      { nombre : 'ADMINISTRADOR' },
    ],
    canActivate : [ RolGuard ] 
  },
  {
    path: 'lista',
    component: ListaUsuariosComponent,
    data : [
      { nombre : 'ADMINISTRADOR' },
    ],
    canActivate : [ RolGuard ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsuarioRoutingModule { }
