import { Injectable } from '@angular/core';
import { DigitalDocsService } from 'src/app/services/digital-docs.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICargoUserDTO } from '../models/cargo-user-dto';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CargoService extends DigitalDocsService {

  constructor(private http: HttpClient) {
    super('api/constantes', http);
  }

  public getlistaCargos(): Observable<ICargoUserDTO[]> {
    return this.http.get<any>(this.url + '/cargos').pipe(
      map(response => {
        return response.data as ICargoUserDTO[];
      })
    );
  }
}
