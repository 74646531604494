<nav class="navbar navbar-expand-lg navbar-absolute fixed-top">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <a [routerLink]="[ '/home' ]">
                <h2 class="hero-nav">
                    SIS-G@@ "Sistema de Gesti&oacute;n Administrativa Acad&eacute;mica"
                </h2>
            </a>
        </div>
        <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav">
                <li >
                    <a [routerLink]="[ '/home/invitado/busqueda-perfil']" class="nav-link" >
                        <i class="mdi mdi-magnify"></i>
                        <span>BUSQUEDA DE PERFILES</span>
                    </a>
                </li>
                <li >
                    <a [routerLink]="[ '/home/invitado/registrar-estudiante']" class="nav-link" >
                        <i class="mdi mdi-plus-box"></i>
                        <span>REGISTRO DE ESTUDIANTE</span>
                    </a>
                </li>

                <li  ngbDropdown placement="bottom-left">
                    <link rel="stylesheet" href="">
                    <a [routerLink]="[ '/home/autentificacion/login']" class="nav-link" id="navbarDropdownMenuLink">
                        <i class="nc-icon nc-single-02"></i>
                        <span>Iniciar Sesion</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
