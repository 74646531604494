<div class="card">
  <div class="card-header">
    <h4 class="card-title"><b>MOSTRAR USUARIO</b></h4>
  </div>
  <div class="card-body" *ngIf="usuario">
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="usuario-nombres"><b>Nombres:</b></label>
        <p>{{usuario.nombres}}</p>
      </div>
      <div class="form-group col-md-4">
        <label for="usuario-apellido-paterno"><b>Apellido Paterno:</b></label>
        <p>{{usuario.apellidoPaterno}}</p>
      </div>
      <div class="form-group col-md-4">
        <label for="usuario-apellido-materno"><b>Apellido Materno:</b></label>
        <p>{{usuario.apellidoMaterno}}</p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-5">
        <label for="usuario-correo"><b>Cargos: </b></label>
        <ul *ngFor="let cargo of usuario.cargos">
          <li>{{cargo.nombre}}</li>
        </ul>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-5">
        <label for="usuario-correo"><b>Correo Electronico: </b></label>
        <p>{{usuario.email}}</p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4">
        <label for="usuario-nombre-usuario"><b>Fecha Creación: </b></label>
        <p>{{usuario.fechaCreacion | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="form-group col-md-4">
        <label for="usuario-nombre-usuario"><b>Fecha Modificación: </b></label>
        <p>{{usuario.fechaModificacion | date: 'dd/MM/yyyy'}}</p>
      </div>
    </div>
    <div class="form-group">
      <button type="button" class="btn btn-danger mx-2" [routerLink]="['/usuarios/lista']">SALIR</button>
    </div>

  </div>
</div>