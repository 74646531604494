import { Component, OnInit } from '@angular/core';
import { ROUTES_ADMIN, ROUTES_TUTOR, ROUTES_DIRECTOR, ROUTES_DOCENTE, ROUTES_TRIBUNAL, ROUTES_ESTUDIANTE, ROUTES_SECRETARIA, ROUTES_CONSEJERO,ROUTES_RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO,ROUTES_DOCENTE_MATERIA,ROUTES_POSTULANTE_AUXILIATURA } from './menu-items';
import { InfoUsuarioService } from '../autentificacion/services/info-usuario.service';

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[] = [];

  private diccionarioCargo: {} = {
    'DIRECTOR_CARRERA': ROUTES_DIRECTOR,
    'DOCENTE_REGULAR': ROUTES_DOCENTE,
    'TUTOR_ESTUDIANTE': ROUTES_TUTOR,
    'ADMINISTRADOR': ROUTES_ADMIN,
    'TRIBUNAL': ROUTES_TRIBUNAL,
    'ESTUDIANTE': ROUTES_ESTUDIANTE,
    'SECRETARIA': ROUTES_SECRETARIA,
    'CONSEJERO' : ROUTES_CONSEJERO,
    'RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO': ROUTES_RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO,
    'DOCENTE_MATERIA': ROUTES_DOCENTE_MATERIA,
    'POSTULANTE_AUXILIATURA' : ROUTES_POSTULANTE_AUXILIATURA
  };

  constructor(private infoUsuarioService: InfoUsuarioService) {
  }

  ngOnInit() {
    this.loadMenu();
  }

  private loadMenu() {
    this.infoUsuarioService.getMiCargo().subscribe(resp => {
      for (let i = 0; i < resp.length; i++) {
        this.diccionarioCargo[resp[i].id].filter(menuItem => {
          let item:any[] = this.getItem(menuItem.title);
          if(item[1]==false){
            this.menuItems.push(menuItem);
          }else{
            let index = item[0];
            if(menuItem.prioridad > this.menuItems[index].prioridad ){
              this.menuItems.splice(index,1,menuItem);
            }
          }
        });
      }

      this.menuItems = this.menuItems.filter((elem, index, array) => index === array.indexOf(elem));     
    });
  }

  private getItem(nombreItem:string):any[] {
    let ans:any[] = [];
    for (let i = 0; i < this.menuItems.length; i++) {
      if(this.menuItems[i].title == nombreItem){
        ans.push(i);
        ans.push(true);
        break;
      }      
    }

    if(ans.length==0){
      ans.push(-1);
      ans.push(false);
    }
    return ans;
  }
}

