<ul class="nav">
    <li [class.active]="isLastFunctionActive(menu.path)" [ngClass]="menu.class">
        <a class="reduc_margen mr-0" [routerLink]="menu.path ? [menu.path] : null" (click)="menu.isCollapsed = !menu.isCollapsed" [attr.aria-expanded]="!menu.isCollapsed" aria-controls="collapseExample" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <i class="nc-icon {{menu.icon}} mr-1"></i>
            <p>{{menu.title}}</p>
        </a>
        <div class="pl-4" id="collapseExample" [ngbCollapse]="menu.isCollapsed">
            <div class="bg-info">
                <div class="posic_div">
                    <app-sub-sidebar *ngFor="let subMenu of menu.subRuta" [menu]="subMenu"></app-sub-sidebar>
                </div>
            </div>
        </div>
    </li>
</ul>
