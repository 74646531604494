import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUsuarioDTO } from '../../models/usuario-dto';
import { Subscription } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { ICargoUserDTO } from '../../models/cargo-user-dto';
import { CargoService } from '../../services/cargo.service';
import { PaginatedData } from 'src/app/digital-core/models/paginatedData';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html',
  styleUrls: ['./lista-usuarios.component.scss']
})
export class ListaUsuariosComponent implements OnInit, OnDestroy {
  public titleTable: String;
  public listaCargos: ICargoUserDTO[];
  public cargos: [];
  public listaUsuarios: PaginatedData<IUsuarioDTO> = new PaginatedData();
  public controlItemperPerPage: FormControl = new FormControl(10);
  private subscriptions: Subscription = new Subscription();
  
  usuarioSeleccionado:IUsuarioDTO;
  cargoSeleccionado:string;

  constructor(private usuarioService: UsuarioService,
              private cargoService: CargoService,
              private toast: ToastrService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.titleTable = "LISTA DE USUARIOS";
    this.loadDatos();
    this.cargarDatos(this.listaUsuarios.page);
    const sub1$ = this.controlItemperPerPage.valueChanges
      .subscribe(
        (value) => {
          this.listaUsuarios.page = 1;
          this.listaUsuarios.pageSize = Number(value);
          this.cargarDatos(this.listaUsuarios.page);
        }
      );
    this.subscriptions.add(sub1$);
  }

  loadDatos(): void {
    const subCargos$ = this.cargoService.getlistaCargos().subscribe(
      resp => {
        this.listaCargos = resp;
        this.cargos = this.loadCargos()
      });
    this.subscriptions.add(subCargos$);
  }

  cargarDatos(pageNumber: number, cargo?: string): void {
    const sub4$ = this.usuarioService.getListaUsuariosPaginado(pageNumber - 1, this.listaUsuarios.pageSize, cargo)
      .subscribe(response => {
        this.listaUsuarios.collection = response.lista;
        this.listaUsuarios.page = response.nroPagina + 1;
        this.listaUsuarios.collectionSize = response.totalElementos;
      });
    this.subscriptions.add(sub4$);
  }

  private loadCargos(): any {
    let res = {};
    this.listaCargos.forEach(cargo => {
      res[cargo.id] = cargo.nombre;
    });
    return res;
  }


  changeDatos(cargo: string): void {
    this.cargoSeleccionado = cargo;
    this.titleTable = "LISTADO DE USUARIOS, SEGUN: " + (String)(this.cargos[cargo]).toUpperCase();
    this.cargarDatos(this.listaUsuarios.page, cargo);
  }

  public habilitarInhabilitarUsuario(modalConfirmacion:any,usuario: IUsuarioDTO){
    this.usuarioSeleccionado = usuario;
    this.modalService.open(modalConfirmacion);
  }

  public habilitarInhabilitarUsuarioService() {
    this.modalService.dismissAll();
    const sub2$ = this.usuarioService.habilitarInhabilitarUsuario(this.usuarioSeleccionado.id).subscribe(
      (resp) => {
        this.toast.success(resp.message, '', {
          timeOut: 30000,
          closeButton: true,
          enableHtml: true,
        });
        this.loadDatos();
        this.cargarDatos(this.listaUsuarios.page,this.cargoSeleccionado);
      }, (err) => {
        for (const e of err.error.errors) {
          this.toast.error(e, '', {
            timeOut: 30000,
            closeButton: true,
            enableHtml: true,
          });
        }
      });
    this.subscriptions.add(sub2$);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
