<div class="card">
  <div class="card-header">
    <h4 class="card-title"><b>EDITAR USUARIO</b></h4>
    <button type="button" class="btn btn-warning text-white"
        (click)="abrirConfirmacion(modalCambiarContrasenia)">CAMBIAR CONTRASEÑA</button>
  </div>
  <div class="card-body" *ngIf="usuario">
    <form [formGroup]="formUsuario">
      <div class="form-group col-md-5">
        <label for="usuario-nombres"><b>Nombres(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-nombres" formControlName="nombres"
          [ngClass]="{ 'is-invalid': nombres.touched && nombres.errors }">
        <div *ngIf="nombres.touched && nombres.errors" class="invalid-feedback">
          <div *ngIf="nombres.errors.required">Nombres es requerido</div>
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="usuario-apellido-paterno"><b>Apellido Paterno(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-apellido-paterno" formControlName="apellidoPaterno"
          [ngClass]="{ 'is-invalid': apellidoPaterno.touched && apellidoPaterno.errors }">
        <div *ngIf="apellidoPaterno.touched && apellidoPaterno.errors" class="invalid-feedback">
          <div *ngIf="apellidoPaterno.errors.required">Apellido Paterno es requerido</div>
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="usuario-apellido-materno"><b>Apellido Materno(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-apellido-materno" formControlName="apellidoMaterno"
          [ngClass]="{ 'is-invalid': apellidoMaterno.touched && apellidoMaterno.errors }">
        <div *ngIf="apellidoMaterno.touched && apellidoMaterno.errors" class="invalid-feedback">
          <div *ngIf="apellidoMaterno.errors.required">Apellido Materno es requerido</div>
        </div>
      </div>
      <div class="form-group col-md-8">
        <label for="usuario-cargo"><b>Cargo(<span class="text-danger">*</span>):</b></label>
        <div class="custom-control custom-checkbox" *ngFor="let cargo of listaCargos">
          <div class="row">
            <input *ngIf="cargo.id != 'CONSEJERO' && cargo.id != 'POSTULANTE_AUXILIATURA' && cargo.id != 'ESTUDIANTE'; else campoDisabled"
              class="custom-control-inline col-1 mr-0" 
              (click)="agregarCargo(cargo.id)" 
              [checked]="verificarCampo(cargo.id)" 
              type="checkbox">

              <ng-template #campoDisabled>
                <input 
                class="custom-control-inline col-1 mr-0" 
                (click)="agregarCargo(cargo.id)" 
                [checked]="verificarCampo(cargo.id)" 
                type="checkbox"
                disabled>
              </ng-template>
            <label>{{cargo.nombre}}</label>
          </div>       
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="usuario-correo"><b>Correo Electronico(<span class="text-danger">*</span>): </b></label>
        <input type="email" class="form-control" id="usuario-correo" formControlName="email"
          [ngClass]="{ 'is-invalid': email.touched && email.errors }">
        <div *ngIf="email.touched && email.errors" class="invalid-feedback">
          <div *ngIf="email.errors.required">Correo es requerido</div>
          <div *ngIf="email.errors.email || email.errors.pattern">
            El correo es invalido</div>
        </div>
      </div>
    </form>
    <div class="form-group pl-3">
      <button type="button" class="btn btn-info" [disabled]="formUsuario.invalid || editando"
        (click)="onSubmit()">EDITAR</button>
      <button type="button" class="btn btn-danger mx-2" (click)="onExit()">SALIR</button>
    </div>
  </div>
</div>

<ng-template #modalCambiarContrasenia let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Usted esta por cambiar la contraseña del Usuario</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="formContrasenia">
      <div class="form-group">
        <label for="usuario-mail"><b>Correo Electronico(<span class="text-danger">*</span>): </b></label>
        <input type="email" class="form-control" id="usuario-mail" formControlName="mail" disabled>
      </div>
      <div class="form-group">
        <label for="usuario-contrasenia"><b>Contraseña: </b></label>
        <input id="usuario-contrasenia" type="password" class="form-control" formControlName="password"
          autocomplete="new-password" [ngClass]="{'is-invalid': password.touched && password.errors}">
        <small id="passwordHelp" class="form-text text-muted">
          La contraseña debe tener como minimo 8 caracteres y por lo menos 1 caracter de cada tipo:
          mayusculas,
          minusculas, numeros.
        </small>
        <div *ngIf="password.touched && password.errors" class="invalid-feedback">
          <div *ngIf="password.errors.pattern">La contraseña es invalida</div>
          <div *ngIf="password.errors.required">La contraseña es requerida</div>
        </div>
      </div>
      <div class="form-group">
        <label for="usuario-confirm-contrasenia"><b>Repetir contraseña: </b></label>
        <input id="usuario-confrim-contrasenia" type="password" class="form-control" formControlName="confirm_password"
          autocomplete="new-password" [ngClass]="{'is-invalid': confirm_password.touched && confirm_password.errors}">
        <div *ngIf="confirm_password.touched && confirm_password.errors" class="invalid-feedback">
          <div *ngIf="confirm_password.errors.mustMatch">La contraseña ingresada no coincide
          </div>
          <div *ngIf="confirm_password.errors.required">El campo es requerido es requerida</div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancelar</button>
    <button type="button" class="btn btn-danger" [disabled]="formContrasenia.invalid" (click)="cambiarContrasenia()">Cambiar</button>
  </div>
</ng-template>