<div class="card">
  <div class="card-header">
    <h4 class="card-title title-list-users"><b>Lista de Usuarios Disponibles en el Sistema</b></h4>
    <div class="col-12 div-filter-create text-right">
      <!-- <button class="btn btn-info" (click)="openModalRegistroExterno()" *ngIf="cargo === ECargo.RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO">
        <i class="mdi mdi-account-plus"></i> REGISTRAR NUEVO RESPONSABLE </button> -->
      <button class="btn btn-info" (click)="openModalRegistroExterno()" *ngIf="cargo === ECargo.TRIBUNAL">
        <i class="mdi mdi-account-plus"></i> REGISTRAR NUEVO TRIBUNAL </button>
    </div>
  </div>
  <div class="card-body">
    <div [formGroup]="searchForm">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label><b>Nombre: </b></label>
          <input class="form-control" formControlName="nombre" placeholder="Buscar por nombre..." />
        </div>
        <div class="form-group col-md-4">
          <label><b>Apellido Paterno: </b></label>
          <input class="form-control" formControlName="apellidoPaterno" placeholder="Buscar por apellido paterno..." />
        </div>
        <div class="form-group col-md-4">
          <label><b>Apellido Materno: </b></label>
          <input class="form-control" formControlName="apellidoMaterno" placeholder="Buscar por apellido materno..." />
        </div>
      </div>
      <div class="form-group mt-0">
        <button class="btn btn-info" (click)="loadDatos()"> Buscar </button>
        <button class="btn btn-warning mx-2" (click)="cleanSearch()"> Limpiar Busqueda </button>
      </div>
    </div>
    <div *ngIf="listaUsuarios?.length == 0" class="alert alert-info">
      NO EXISTEN USUARIOS
    </div>
    <div class="table-responsive table-bordered" *ngIf="listaUsuarios?.length>0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col-3">#</th>
            <th scope="col">Nombres</th>
            <th scope="col">Apellido Paterno</th>
            <th scope="col">Apellido Materno</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor=" let indice=index; let usuario of listaUsuarios">
            <td scope="row"><b>{{indice + 1}}</b></td>
            <td>{{usuario.nombre}}</td>
            <td>{{usuario.apellidoPaterno}}</td>
            <td>{{usuario.apellidoMaterno}}</td>
            <td>
              <a title="Mostrar datos usuario" class="mdi-18px mdi-dark mdi mdi-account-check"
                (click)="seleccionarUsuario(usuario)"></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
