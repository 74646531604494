import { Component, OnInit, Input } from '@angular/core';
import { RouteInfo } from '../route-info';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sub-sidebar',
  templateUrl: './sub-sidebar.component.html',
  styleUrls: ['./sub-sidebar.component.css']
})
export class SubSidebarComponent implements OnInit {
  @Input()
  menu: RouteInfo;
  constructor(private router: Router) { }

  isLastFunctionActive(path: string | null): boolean {
    const currentPath = this.router.url.split('?')[0];
    return currentPath === path;
  }

  ngOnInit(): void {
  }

}
