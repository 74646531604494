<footer class="footer">
    <div class="container-fluid text-center">
        Sistema de Gesti&oacute;n Administrativa Acad&eacute;mica - &copy; {{test | date: 'yyyy'}} - hecho por: Laboratorio de Desarrollo(Informatica y Sistemas)
    </div>

    <div class="text-center">
        <i class="fa fa-envelope-open"></i>
        inflabumss@gmail.com
    </div>
</footer>
