import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsuarioRoutingModule } from './usuario-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ListaUsuariosComponent } from './components/lista-usuarios/lista-usuarios.component';
import { RegistroUsuarioComponent } from './components/registro-usuario/registro-usuario.component';
import { MostrarUsuarioComponent } from './components/mostrar-usuario/mostrar-usuario.component';
import { RegistroUsuarioExternoComponent } from './components/registro-usuario-externo/registro-usuario-externo.component';
import { EditarUsuarioComponent } from './components/editar-usuario/editar-usuario.component';
import { UsuariosDisponiblesPerfilComponent } from './components/usuarios-disponibles-perfil/usuarios-disponibles-perfil.component';


@NgModule({
  declarations: [RegistroUsuarioComponent,
    ListaUsuariosComponent,
    MostrarUsuarioComponent,
    RegistroUsuarioExternoComponent,
    EditarUsuarioComponent,
    UsuariosDisponiblesPerfilComponent
  ],
  imports: [
    CommonModule,
    UsuarioRoutingModule,
    NgbModule,
    ReactiveFormsModule
  ]
})
export class UsuarioModule { }
