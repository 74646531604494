<div class="card">
  <div class="card-header">
    <h4 class="card-title" *ngIf="cargoU === ECargo.TUTOR_ESTUDIANTE"><b>REGISTRAR NUEVO TUTOR</b></h4>
    <h4 class="card-title" *ngIf="cargoU === ECargo.RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO"><b>REGISTRAR NUEVO RESPONSABLE</b></h4>
    <h4 class="card-title" *ngIf="cargoU === ECargo.TRIBUNAL"><b>REGISTRAR NUEVO TRIBUNAL</b></h4>
  </div>
  <div class="card-body">
    <form [formGroup]="formUsuario">
      <div class="form-group col-md-12">
        <label for="usuario-nombre"><b>Nombres(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-nombre" formControlName="nombre"
          [ngClass]="{ 'is-invalid': nombre.touched && nombre.errors }">
        <div *ngIf="nombre.touched && nombre.errors" class="invalid-feedback">
          <div *ngIf="nombre.errors.required">Nombres es requerido</div>
        </div>
      </div>
      <div class="form-group col-md-12">
        <label for="usuario-apellido-paterno"><b>Apellido Paterno(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-apellido-paterno" formControlName="apellidoPaterno"
          [ngClass]="{ 'is-invalid': apellidoPaterno.touched && apellidoPaterno.errors }">
        <div *ngIf="apellidoPaterno.touched && apellidoPaterno.errors" class="invalid-feedback">
          <div *ngIf="apellidoPaterno.errors.required">Apellido Paterno es requerido</div>
        </div>
      </div>
      <div class="form-group col-md-12">
        <label for="usuario-apellido-materno"><b>Apellido Materno(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-apellido-materno" formControlName="apellidoMaterno"
          [ngClass]="{ 'is-invalid': apellidoMaterno.touched && apellidoMaterno.errors }">
        <div *ngIf="apellidoMaterno.touched && apellidoMaterno.errors" class="invalid-feedback">
          <div *ngIf="apellidoMaterno.errors.required">Apellido Materno es requerido</div>
        </div>
      </div>
      <div class="form-group col-md-12">
        <label for="usuario-correo"><b>Correo Electronico(<span class="text-danger">*</span>): </b></label>
        <input type="email" class="form-control" id="usuario-correo" formControlName="email"
          [ngClass]="{ 'is-invalid': email.touched && email.errors }">
        <div *ngIf="email.touched && email.errors" class="invalid-feedback">
          <div *ngIf="email.errors.required">Correo es requerido</div>
          <div *ngIf="email.errors.email || email.errors.pattern">
            El correo es invalido</div>
        </div>
      </div>
    </form>
    <div class="form-group pl-3">
      <button type="button" class="btn btn-info" [disabled]="formUsuario.invalid"
        (click)="onSubmit()">GUARDAR</button>
      <button type="button" class="btn btn-danger mx-2" (click)="onExit()">SALIR</button>
    </div>
  </div>
</div>