
<nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
    </div>

    <button *ngIf="banderaEsEstudianteYNoPostulanteAun"
            class="btn btn-success"
            (click)="mostrarModalConfirmacion(modalConfirmacion)">
      Postular a convocatoria
      <span class="mdi mdi-account-check"></span>
    </button>

    <button class="navbar-toggler" type="button" (click)="collapse()"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav">
        <li class="nav-item btn-rotate" ngbDropdown>
          <a class="nav-link btn-rotate" ngbDropdownToggle id="navbar-user-config">
            <i class="nc-icon nc-single-02"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbar-user-config" class="dropdown-menu dropdown-menu-right">
            <a ngbDropdownItem [routerLink]="[ '/yo' ]">Mi Cuenta</a>
            <hr>
            <a ngbDropdownItem (click)="cerrarSesion()">
              <i class="nc-icon nc-user-run"></i>
              <p>Cerrar Sesión</p>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #modalConfirmacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title">POSTULAR A UNA CONVOCATORIA</h4>
  </div>
  <div class="modal-body">
    <p><b>¿Está seguro que desea postular a una convocatoria?</b></p>
  </div>
  <div class="modal-footer">
    <button 
      type="button" 
      class="btn btn-info" 
      (click)="postularAConvocatoria()">SI, ESTOY SEGURO</button>
    <button 
      type="button" 
      class="btn btn-danger mx-2" 
      (click)="modal.close('Close click')">NO, CANCELAR</button>
  </div>
</ng-template>
