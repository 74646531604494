<div class="wrapper">
  <div class="sidebar" data-color="black" data-active-color="primary">
    <sidebar-cmp></sidebar-cmp>
  </div>
  <div class="main-panel">
    <navbar-cmp></navbar-cmp>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
