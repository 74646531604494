import { Injectable } from '@angular/core';
import { DigitalDocsService } from 'src/app/services/digital-docs.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUsuarioDTO } from 'src/app/usuario/models/usuario-dto';
import { map } from 'rxjs/operators';
import { ICargoUserDTO } from 'src/app/usuario/models/cargo-user-dto';

@Injectable({
  providedIn: 'root'
})
export class InfoUsuarioService extends DigitalDocsService {

  constructor(private http: HttpClient) {
    super('api/yo', http);
  }

  public getMiCargo(): Observable<ICargoUserDTO[]> {
    return this.http.get<any>(this.url + '/datos').pipe(
      map(response => {
        return response.data as ICargoUserDTO[];
      })
    );
  }

  public getMiUsuario(): Observable<IUsuarioDTO> {
    return this.http.get<any>(this.url).pipe(
      map(response => {
        return response.data as IUsuarioDTO;
      })
    );
  }

  public getMiCargoConsejero(): Observable<any> {
    return this.http.get<any>(this.url + '/consejero').pipe(
      map(response => {
        return response.data ;
      })
    );
  }
}
