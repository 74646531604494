import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUsuarioDTO } from '../../models/usuario-dto';
import { ICargoUserDTO } from '../../models/cargo-user-dto';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { CargoService } from '../../services/cargo.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IUsuarioEditarVO } from '../../models/usuario-editar-vo';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMiUsuarioVO } from 'src/app/mi-usuario/models/mi-usuario-vo';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss']
})
export class EditarUsuarioComponent implements OnInit, OnDestroy {
  public usuario: IUsuarioDTO;
  public listaCargos: ICargoUserDTO[];
  public cargosSeleccionados: string[] = [];
  public idUsuario: string;
  public formUsuario: FormGroup;
  public formContrasenia : FormGroup;
  private subscriptions: Subscription = new Subscription();

  editando : boolean = false;

  constructor(private fb: FormBuilder, private usuarioService: UsuarioService,
    private cargoService: CargoService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => this.idUsuario = params.id);
    this.buildForm();
    this.loadDatos();
  }

  private loadDatos(): void {
    const subCargos$ = this.cargoService.getlistaCargos().subscribe(
      resp => {
        this.listaCargos = resp;
      });
    this.subscriptions.add(subCargos$);
    const subUser$ = this.usuarioService.getUsuarioById(this.idUsuario).subscribe(resp => {
      this.usuario = resp;
      this.usuario.cargos.forEach(cargo => {
        this.cargosSeleccionados.push(cargo.id);
      });
      this.fillForm();
    });
    this.subscriptions.add(subUser$);
  }

  buildForm(): void {
    this.formUsuario = this.fb.group({
      apellidoMaterno: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,
      Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
      nombres: ['', Validators.required],
    }, {
      validator: [this.validarApellidos('apellidoPaterno', 'apellidoMaterno')]
    });
  }

  onSubmit() {
    const usuarioEnviar: IUsuarioEditarVO = {
      nombres: this.nombres.value,
      apellidoPaterno: this.apellidoPaterno.value,
      apellidoMaterno: this.apellidoMaterno.value,
      email: this.email.value,
      cargos: this.cargosSeleccionados,
    };
    this.editarUsuario(usuarioEnviar);
  }

  onExit() {
    this.router.navigate(['/usuarios/lista']);
  }

  editarUsuario(usuarioE: IUsuarioEditarVO): void {
    this.editando = true;
    const sub2$ = this.usuarioService.editUsuario(usuarioE, this.idUsuario).subscribe(
      (resp) => {
        this.toast.success(resp.message, '', {
          timeOut: 30000,
          closeButton: true,
          enableHtml: true,
        });
        this.editando = false;
        this.router.navigate(['/usuarios/lista']);
    }, (err) => {
      for (const e of err.error.errors) {
        this.toast.error(e, '', {
          timeOut: 30000,
          closeButton: true,
          enableHtml: true,
        });
      }
    });
    this.subscriptions.add(sub2$);
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup): void => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  validarApellidos(apellidoP: string, apellidoM: string) {
    return (group: FormGroup): void => {
      const apellidoPControl = group.controls[apellidoP];
      const apellidoMControl = group.controls[apellidoM];
      if (apellidoPControl.value !== '' && apellidoMControl.value !== '') {
        return;
      }
      if (apellidoPControl.value !== '' && apellidoMControl.value === '') {
        apellidoMControl.setErrors({ required: false });
        apellidoMControl.setErrors(null);
      } else if (apellidoPControl.value === '' && apellidoMControl.value !== '') {
        apellidoPControl.setErrors({ required: false });
        apellidoPControl.setErrors(null);
      }
    };
  }

  fillForm() {
    this.nombres.setValue(this.usuario.nombres);
    this.apellidoPaterno.setValue(this.usuario.apellidoPaterno);
    this.apellidoMaterno.setValue(this.usuario.apellidoMaterno);
    this.email.setValue(this.usuario.email);
  }

  verificarCampo(id: string): boolean {
    if (this.cargosSeleccionados.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  agregarCargo(id: string): void {
    if (this.cargosSeleccionados.length < 1) {
      this.cargosSeleccionados.push(id);
    } else {
      if (this.cargosSeleccionados.includes(id)) {
        this.cargosSeleccionados = this.cargosSeleccionados.filter((elem, i, a) => elem !== id);
      } else {
        this.cargosSeleccionados.push(id);
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  abrirConfirmacion(modalContrasenia){
    const modal = this.modalService.open(modalContrasenia, {ariaLabelledBy: 'modal-basic-title'});
    this.buildFormContrasenia(this.usuario.email);
  }

  buildFormContrasenia(email: string): void {
    this.formContrasenia = this.fb.group({
      mail: email,
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d].{8,}')]],
      confirm_password: ['', Validators.required],
    }, {
      validator: [this.mustMatch('password', 'confirm_password')]
    });
  }

  cambiarContrasenia(){
    const correoYContraseña: IMiUsuarioVO = this.formContrasenia.value;
    this.actualizarEmailContraseña(correoYContraseña);
    this.modalService.dismissAll();
  }

  actualizarEmailContraseña(correoYContraseña: IMiUsuarioVO): void {
    this.usuarioService.editUsuarioContrasenia(correoYContraseña, this.idUsuario).subscribe(resp => {
      this.toast.success(resp.message, '', {
        timeOut: 30000,
        closeButton: true,
        enableHtml: true,
      });
    }, err => {
      for (const e of err.error.errors) {
        this.toast.error(e, '', {
          timeOut: 30000,
          closeButton: true,
          enableHtml: true,
        });
      }
    });
  }

  get f() {
    return this.formUsuario.controls;
  }
  get nombres(): FormControl {
    return this.formUsuario.get('nombres') as FormControl;
  }
  get apellidoPaterno(): FormControl {
    return this.formUsuario.get('apellidoPaterno') as FormControl;
  }
  get apellidoMaterno(): FormControl {
    return this.formUsuario.get('apellidoMaterno') as FormControl;
  }
  get email(): FormControl {
    return this.formUsuario.get('email') as FormControl;
  }
  get password(): FormControl {
    return this.formContrasenia.get('password') as FormControl;
  }
  get confirm_password(): FormControl {
    return this.formContrasenia.get('confirm_password') as FormControl;
  }

}

