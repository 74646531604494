
<div *ngIf="isLoading$|async">
<div id="overlay" class="lds-spinner">
  <div id ="text">
 <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  </div>
</div>

 
</div>



