<div class="card">
  <div class="card-header">
    <h4 class="card-title"><b>REGISTRAR NUEVO USUARIO</b></h4>
  </div>
  <div class="card-body">
    <form [formGroup]="formUsuario">
      <div class="form-group col-md-5">
        <label for="usuario-nombres"><b>Nombres(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-nombres" formControlName="nombres"
          [ngClass]="{ 'is-invalid': nombres.touched && nombres.errors }">
        <div *ngIf="nombres.touched && nombres.errors" class="invalid-feedback">
          <div *ngIf="nombres.errors.required">Nombres es requerido</div>
          <div *ngIf="nombres.errors.pattern">Debes ingresar un nombre válido</div>
          <div *ngIf="nombres.errors.maxlength">Maximo 30 caracteres</div>
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="usuario-apellido-paterno"><b>Apellido Paterno(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-apellido-paterno" formControlName="apellidoPaterno"
          [ngClass]="{ 'is-invalid': apellidoPaterno.touched && apellidoPaterno.errors }">
        <div *ngIf="apellidoPaterno.touched && apellidoPaterno.errors" class="invalid-feedback">
          <div *ngIf="apellidoPaterno.errors.required">Apellido Paterno es requerido</div>
          <div *ngIf="apellidoPaterno.errors.pattern">Debes ingresar un apellido válido</div>
          <div *ngIf="apellidoPaterno.errors.maxlength">Maximo 30 caracteres</div>
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="usuario-apellido-materno"><b>Apellido Materno(<span class="text-danger">*</span>):</b></label>
        <input type="text" class="form-control" id="usuario-apellido-materno" formControlName="apellidoMaterno"
          [ngClass]="{ 'is-invalid': apellidoMaterno.touched && apellidoMaterno.errors }">
        <div *ngIf="apellidoMaterno.touched && apellidoMaterno.errors" class="invalid-feedback">
          <div *ngIf="apellidoMaterno.errors.required">Apellido Materno es requerido</div>
          <div *ngIf="apellidoMaterno.errors.pattern">Debes ingresar un apellido válido</div>
          <div *ngIf="apellidoMaterno.errors.maxlength">Maximo 30 caracteres</div>
        </div>
      </div>

      <div class="form-group col-md-8">
        <label for="usuario-cargo" ><b>Cargo(<span class="text-danger">*</span>):</b></label>

        <div class="custom-control custom-checkbox" *ngFor="let cargo of listaCargos;index as i">
          <div class="row">
            <input *ngIf="cargo.id != 'CONSEJERO'"
              class="custom-control-inline col-1 mr-0"
              id="c{{i}}"
              (click)="agregarCargo(cargo.id,i)"
              type="checkbox"
            > <!--*ngIf="cargo.id != 'CONSEJERO'; else consejeroDisabled"-->

            <!--<ng-template #consejeroDisabled>
              <input
                class="custom-control-inline col-1 mr-0"
                (click)="agregarCargo(cargo.id,i)"
                type="checkbox"
                disabled
              >
            </ng-template>-->


            <label *ngIf="cargo.id != 'CONSEJERO'">{{cargo.nombre}}</label>
          </div>
        </div>
      </div>

      <div class="form-group col-md-5">
        <label for="usuario-correo"><b>Correo Electronico(<span class="text-danger">*</span>): </b></label>
        <input type="email" class="form-control" id="usuario-correo" formControlName="email"
          [ngClass]="{ 'is-invalid': email.touched && email.errors }">
        <div *ngIf="email.touched && email.errors" class="invalid-feedback">
          <div *ngIf="email.errors.required">Correo es requerido</div>
          <div *ngIf="email.errors.email || email.errors.pattern">
            El correo es inválido</div>
        </div>
      </div>

      <div class="form-group col-md-5">
        <label for="usuario-contrasenia"><b>Contraseña: </b></label>
        <input id="usuario-contrasenia" type="password" class="form-control" formControlName="password"
          autocomplete="new-password" [ngClass]="{'is-invalid': password.touched && password.errors}" onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))">
        <small id="passwordHelp" class="form-text text-muted">
          La contraseña debe tener como mínimo 8 caracteres y por lo menos 1 caracter de tipo:
          Mayúsculas, minúsculas y números. NO SE ACEPTAN SIMBOLOS COMO: "·#~€~$&,"
        </small>
        <div *ngIf="password.touched && password.errors" class="invalid-feedback">
          <div *ngIf="password.errors.pattern">La contraseña es inválida</div>
          <div *ngIf="password.errors.required">La contraseña es requerida</div>
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="usuario-confirm-contrasenia"><b>Repetir contraseña: </b></label>
        <input id="usuario-confrim-contrasenia" type="password" class="form-control" formControlName="confirm_password"
          autocomplete="new-password" [ngClass]="{'is-invalid': confirm_password.touched && confirm_password.errors}" onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))">
        <div *ngIf="confirm_password.touched && confirm_password.errors" class="invalid-feedback">
          <div *ngIf="confirm_password.errors.mustMatch">La contraseña ingresada no coincide
          </div>
          <div *ngIf="confirm_password.errors.required">El campo es requerido es requerida</div>
        </div>
      </div>
    </form>
    <div class="form-group pl-3">
      <button type="button" class="btn btn-info" [disabled]="formUsuario.invalid"
        (click)="onSubmit()">GUARDAR</button>
      <button type="button" class="btn btn-danger mx-2" (click)="onExit()">SALIR</button>
    </div>
  </div>
</div>
