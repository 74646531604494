import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserToken } from '../models/user-token';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class RolGuard implements CanActivate {
  constructor(private router : Router){}

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token   : string = localStorage.getItem('token'); 
    let decoded : UserToken = jwt_decode(token); 

    let rolesRuta:string[]=[];
    route.routeConfig.data.forEach(rol => {
      rolesRuta.push(rol.nombre);
    });

    let rolesUsuario:string[]=[];
    decoded.authorities.forEach(rol => {
      rolesUsuario.push(rol);
    });
    
    let rutaPermitida = this.existeRol(rolesRuta,rolesUsuario);
    if(!rutaPermitida){  
      localStorage.removeItem('token');
      this.router.navigate(['/home/autentificacion/login']);
    }
          
    return true;
  }
  
  private existeRol(rolesRuta:string[],rolesUsuario:string[]):boolean{
    let existe:boolean = false;
    let i:number = 0;
    while (i<rolesUsuario.length && !existe) {
      let rol:string = rolesUsuario[i++];
      existe = rolesRuta.includes(rol);
    }
    return existe;
  }
}
