import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IDataSearchUsuariosVO } from '../../models/data-search-usuarios-vo';
import { RegistroUsuarioExternoComponent } from '../registro-usuario-externo/registro-usuario-externo.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUsuarioBuscadorDTO } from '../../models/usuario-buscador-dto';
import { ECargo } from '../../models/cargo.enum';

@Component({
  selector: 'app-usuarios-disponibles-perfil',
  templateUrl: './usuarios-disponibles-perfil.component.html',
  styleUrls: ['./usuarios-disponibles-perfil.component.scss']
})
export class UsuariosDisponiblesPerfilComponent implements OnInit, OnDestroy {
  @Input() cargo: string;
  @Input() modal: NgbModal;
  @Input() funcionCargarDatos: Function;
  public listaUsuarios: IUsuarioBuscadorDTO[];
  public tipo = '';
  public ECargo = ECargo;
  public searchForm: FormGroup;
  private subscriptions: Subscription = new Subscription();
  constructor(private fb: FormBuilder,   private modalService: NgbModal,
              private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.buildSearchForm();
    this.loadDatos();
  }

  private buildSearchForm(): void {
    this.searchForm = this.fb.group({
      nombre: [''],
      apellidoPaterno: [''],
      apellidoMaterno: [''],
    });
  }

  loadDatos(): void {
    const data: IDataSearchUsuariosVO = this.searchForm.value;
    const sub1$ = this.usuarioService.listaUsuariosDisponiblesParaRegistarPerfil(data).subscribe(resp => {
      this.listaUsuarios = resp;
    });
    this.subscriptions.add(sub1$);
  }

  seleccionarUsuario(user: IUsuarioBuscadorDTO) {
    if (this.modal) {
      this.funcionCargarDatos(user);
      this.modal.dismissAll();
    }
  }

  openModalRegistroExterno() {
    const funcionCargarTutores = (data) => {
      this.funcionCargarDatos(data);
     };
    const modalCrearUsuarioExterno = this.modalService.open(RegistroUsuarioExternoComponent, { size: 'lg' });
    modalCrearUsuarioExterno.componentInstance.modal = this.modalService;
    modalCrearUsuarioExterno.componentInstance.cargoU = this.cargo;
    modalCrearUsuarioExterno.componentInstance.funcionCargarTutores = funcionCargarTutores;
  }

  cleanSearch(): void {
    this.searchForm.reset({
      nombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
    });
    this.loadDatos();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
