import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from '../services/token.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private tokenService: TokenService,
              private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    if (this.tokenService.getAccessToken()) {
      if (req.url.includes('/usuario/estudiante') || req.url.includes('/auth/login')
          || req.url.includes('/publica/api/perfilesregistrados')) {

      } else {
        req = this.addToken(req, this.tokenService.getAccessToken());
      }
    }
    return next.handle(req).pipe(
      catchError(e => {
        if (e.status === 401) {
          this.router.navigate(['home/autentificacion/login']);
        } else {
          return throwError(e);
        }
      }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
