<div class="card">
  <div class="card-header">
    <h4 class="card-title title-list-users"><b>{{ titleTable }}</b></h4>
    <div class="col-12 div-filter-create text-right">
      <div ngbDropdown placement="bottom-right" class="d-inline-block">
        <button class="btn btn-outline-secundary" id="dropdownBasic1" ngbDropdownToggle>Filtrar</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="ngOnInit()">Todos</button>
          <button ngbDropdownItem (click)="changeDatos('SECRETARIA')">Secretaria</button>
          <button ngbDropdownItem (click)="changeDatos('ADMINISTRADOR')">Administradores</button>
          <button ngbDropdownItem *ngFor="let cargo of listaCargos" (click)="changeDatos(cargo.id)">{{cargo.nombre}}</button>
        </div>
      </div>
      <button class="btn btn-info" [routerLink]="['/usuarios/registro']">
        <i class="mdi mdi-account-plus"></i> Registrar Nuevo Usuario</button>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="listaUsuarios.collection?.length == 0" class="alert alert-info">
      NO EXISTEN USUARIOS
    </div>
    <div class="table-responsive table-bordered" *ngIf="listaUsuarios.collection?.length>0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col-3">#</th>
            <th scope="col">Nombres</th>
            <th scope="col">Apellido Paterno</th>
            <th scope="col">Apellido Materno</th>
            <th scope="col">Email</th>
            <th scope="col">Cargos</th>
            <th scope="col">Fecha Creación</th>
            <th scope="col">Fecha Modificación</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor=" let indice=index; let usuario of listaUsuarios.collection">
            <td scope="row"><b>{{indice + 1}}</b></td>
            <td>{{usuario.nombres}}</td>
            <td>{{usuario.apellidoPaterno}}</td>
            <td>{{usuario.apellidoMaterno}}</td>
            <td>{{usuario.email}}</td>
            <td><span *ngFor="let cargo of usuario.cargos; let i=index">
                <span *ngIf="i<usuario.cargos.length -1">{{cargo.nombreAbreviado}}, </span>
                <span *ngIf="i === usuario.cargos.length -1">{{cargo.nombreAbreviado}} </span>
              </span>
            </td>
            <td>{{usuario.fechaCreacion | date: 'dd/MM/yyyy'}}</td>
            <td>{{usuario.fechaModificacion | date: 'dd/MM/yyyy'}}</td>
            <td>
              <a title="Mostrar datos usuario" class="mdi-18px mdi-dark mdi mdi-information"
                [routerLink]="['/usuarios/mostrar', usuario.id]"></a>
              <a title="Editar datos usuario" class="mdi-18px mdi-dark mdi mdi-pencil"
                [routerLink]="['/usuarios/edicion', usuario.id]"></a>
              <a title="Eliminar usuario" class="mdi-18px mdi-dark mdi mdi-delete"
                (click)="habilitarInhabilitarUsuario(contentHabilitarInhabilitarUsuario,usuario)"></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-lg-8 col-md-7 col-xs-12">
          <ngb-pagination [collectionSize]="listaUsuarios.collectionSize" [(page)]="listaUsuarios.page"
            [pageSize]="listaUsuarios.pageSize" [maxSize]="5" [boundaryLinks]="true" (pageChange)="cargarDatos($event)">
          </ngb-pagination>
        </div>
        <div class="col-lg-4 col-md-3 col-xs-12">
          <select [formControl]="controlItemperPerPage" class="custom-select">
            <option *ngFor="let item of listaUsuarios.itemsPerPage" [value]="item">{{ item }} elementos por pagina
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal confirmation -->
<ng-template #contentHabilitarInhabilitarUsuario let-modal>
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-title" >Inhabilitar Usuario</h4>
  </div>
  <div class="modal-body">    
    <p><b>¿Esta seguro de Inhabilitar al usuario : {{usuarioSeleccionado.nombres}} {{usuarioSeleccionado.apellidoPaterno}} {{usuarioSeleccionado.apellidoMaterno}}, con Emai: {{usuarioSeleccionado.email}} </b></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger mx-2" (click)="modal.close('Close click')">NO, CANCELAR</button>
    <button type="button" class="btn btn-info" (click)="habilitarInhabilitarUsuarioService()">SI, ESTOY SEGURO</button>
  </div>
</ng-template>