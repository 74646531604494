import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './autentificacion/interceptors/token-interceptor.service';
import { GuestLayoutComponent } from './layouts/guest-layout/guest-layout.component';
import { NavbarGuestModule } from './shared/navbar-guest/navbar-guest.module';
import { HomeComponent } from './shared/home/home.component';
import { UsuarioModule } from "./usuario/usuario.module";
import { SpinerPawModule } from './modulo-auxiliatura/components/spiner-paw/spiner-paw.module';

import { CommonModule } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);

@NgModule({
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        GuestLayoutComponent,
        HomeComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        { provide: LOCALE_ID, useValue: 'es-ES' }
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true
        }),
        SidebarModule,
        NavbarModule,
        NavbarGuestModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        FooterModule,
        UsuarioModule,
        SpinerPawModule,
        CommonModule
    ]
})
export class AppModule { }
