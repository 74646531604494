import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUsuarioDTO } from '../../models/usuario-dto';
import { Subscription } from 'rxjs';
import { UsuarioService } from '../../services/usuario.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-mostrar-usuario',
  templateUrl: './mostrar-usuario.component.html',
  styleUrls: ['./mostrar-usuario.component.scss']
})
export class MostrarUsuarioComponent implements OnInit, OnDestroy {
  public usuario: IUsuarioDTO;
  public idUsuario: string;
  private subscriptions: Subscription = new Subscription();
  constructor(private usuarioService: UsuarioService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => this.idUsuario = params.id);
    this.loadDatos();
  }

  private loadDatos(): void {
    const subUser$ = this.usuarioService.getUsuarioById(this.idUsuario).subscribe(resp => {
      this.usuario = resp;
    });
    this.subscriptions.add(subUser$);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
