import { Component } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spiner-paw',
  templateUrl: './spiner-paw.component.html',
  styleUrls: ['./spiner-paw.component.scss'],
})
export class SpinerPawComponent {
isLoading$ = this.spinnerSvc.isLoading$;
  constructor(private spinnerSvc: SpinnerService){}
 /* showSpinner = false;
  constructor(private spinnerService: SpinnerService, private cdRef: ChangeDetectorRef) {

  }
  ngOnInit(): void {
    this.init();
  }
  init() {
    this.spinnerService.getSpinnerObserver().subscribe((status) => {
      this.showSpinner = status === 'start';
      this.cdRef.detectChanges();
    })
  }
*/
}
