import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { SubSidebarComponent } from './sub-sidebar/sub-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [ RouterModule, CommonModule,
        NgbModule ],
    declarations: [ SidebarComponent, SubSidebarComponent ],
    exports: [ SidebarComponent ]
})

export class SidebarModule {}
