import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DigitalDocsService } from 'src/app/services/digital-docs.service';
import { UsuarioCargosDTO } from '../models/usuarioCargosDTO';
import { TokenService } from '../../autentificacion/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService extends DigitalDocsService {

  constructor(private http: HttpClient,
    private tokenService: TokenService) {
    super('api/usuario', http);
  }

  public getUsuariosCargo(cargo: String): Observable<UsuarioCargosDTO[]> {
    return this.http.get<any>(this.url + '/cargo/' + cargo).pipe(
      map(response => {
        return response.data as UsuarioCargosDTO[];
      })
    );
  }

  public crearUsuarioPostulante(idUsuarioPostulante: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('idUsuario', this.cambiarNull(String(idUsuarioPostulante)));
    return this.http.post<any>(`${this.url}/usuario-postulante`, {}, { params }).pipe(
      map(response => {
        this.tokenService.setAccessToken(response['data']);
        this.tokenService.setRefreshToken(response['data']);
        return response['data'] as any;
      })
    );
  }

  private cambiarNull(valor: string): string {
    return (valor === null) ? '' : valor;
  }
}
