import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IUsuarioExternoVO } from '../../models/usuario-externo-vo';
import { UsuarioService } from '../../services/usuario.service';
import { ECargo } from '../../models/cargo.enum';
import { IUsuarioBuscadorDTO } from '../../models/usuario-buscador-dto';

@Component({
  selector: 'app-registro-usuario-externo',
  templateUrl: './registro-usuario-externo.component.html',
  styleUrls: ['./registro-usuario-externo.component.scss']
})
export class RegistroUsuarioExternoComponent implements OnInit, OnDestroy {
  public ECargo = ECargo;
  public idUsuario: string;
  public tipoForm = '';
  public formUsuario: FormGroup;
  @Input() modal: NgbModal;
  @Input() cargoU: string;
  @Input() funcionCargarTutores: Function;
  private subscriptions: Subscription = new Subscription();
  constructor(private fb: FormBuilder, private usuarioService: UsuarioService,
              private router: Router,
              private toast: ToastrService) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.formUsuario = this.fb.group({
      nombre: ['', Validators.required],
      apellidoMaterno: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,
      Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
    }, {
      validator: [this.validarApellidos('apellidoPaterno', 'apellidoMaterno')]
    });
  }

  onSubmit() {
    const usuarioEnviar: IUsuarioExternoVO = this.formUsuario.value;
    usuarioEnviar.cargoUsuario = this.cargoU;
    this.registrarUsuario(usuarioEnviar);
  }

  onExit() {
    if (this.modal) {
      this.modal.dismissAll();
    }
  }

  registrarUsuario(usuarioE: IUsuarioExternoVO): void {
    const sub1$ = this.usuarioService.postUsuarioExterno(usuarioE).subscribe(resp => {
      if (this.modal) {
        this.funcionCargarTutores(resp.data);
        this.modal.dismissAll();
      }
      this.toast.success(resp.message, '', {
        timeOut: 30000,
        closeButton: true,
        enableHtml: true,
      });
      this.formUsuario.reset();
    }, err => {
      for (const e of err.error.errors) {
        this.toast.error(e, '', {
          timeOut: 30000,
          closeButton: true,
          enableHtml: true,
        });
      }
    });
    this.subscriptions.add(sub1$);
  }

  validarApellidos(apellidoP: string, apellidoM: string) {
    return (group: FormGroup): void => {
      const apellidoPControl = group.controls[apellidoP];
      const apellidoMControl = group.controls[apellidoM];
      if (apellidoPControl.value !== '' && apellidoMControl.value !== '') {
        return;
      }
      if (apellidoPControl.value !== '' && apellidoMControl.value === '') {
        apellidoMControl.setErrors({ required: false });
        apellidoMControl.setErrors(null);
      } else if (apellidoPControl.value === '' && apellidoMControl.value !== '') {
        apellidoPControl.setErrors({ required: false });
        apellidoPControl.setErrors(null);
      }
    };
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  get f() {
    return this.formUsuario.controls;
  }
  get nombre(): FormControl {
    return this.formUsuario.get('nombre') as FormControl;
  }
  get apellidoPaterno(): FormControl {
    return this.formUsuario.get('apellidoPaterno') as FormControl;
  }
  get apellidoMaterno(): FormControl {
    return this.formUsuario.get('apellidoMaterno') as FormControl;
  }
  get email(): FormControl {
    return this.formUsuario.get('email') as FormControl;
  }
  get cargo(): FormControl {
    return this.formUsuario.get('cargo') as FormControl;
  }
}

