import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Injectable } from '@angular/core'; @Injectable({
    providedIn: 'root',
})
export class SpinnerService {
   isLoading$ = new Subject<boolean>();
   show():void{
    this.isLoading$.next(true);
   }
   hide():void {
    this.isLoading$.next(false);
   }

  /*
   private count = 0;
    private spinner$ = new BehaviorSubject<string>('');

  getSpinnerObserver(): Observable<string> {
        return this.spinner$.asObservable();
    }

    requestStarted() {
        if (++this.count === 1) {
            this.spinner$.next('start');
        }

    }
    requestEnded() {
        if (this.count === 0 || --this.count === 0) {
            this.spinner$.next('stop');
        }
    }
    resetSpinner() {
        this.count = 0;
        this.spinner$.next('stop');

    }*/
}

