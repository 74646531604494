import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { GuestLayoutComponent } from './layouts/guest-layout/guest-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home/autentificacion/login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: GuestLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../app/layouts/guest-layout/guest-layout-routing.module').then(m => m.GuestLayoutRoutingModule)
      }]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../app/layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];
