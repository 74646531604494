<div class="text-center">
  <h1>SIS-G@@ "Sistema de Gesti&oacute;n Administrativa Acad&eacute;mica"</h1>
  <span>Para la realización de actividades administrativas en modalidad digital para la Facultad de Ciencias y
    Tecnología, </span>
  <div>
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img style="max-width: 70%;" src="assets/img/home/persona_escribiendo.jpg" alt="First slide">
          <div class="carousel-caption d-none d-md-block">
            <h5 class="bg-info text-white">Registro de firmas</h5>
            <p class="bg-info text-white">Cuanto usted tenga algun documento que firmar, podra dar el visto bueno a
              documentos por esta plataforma, evitando el contacto con otras personas</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
