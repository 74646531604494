import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';
import { InfoUsuarioService } from './info-usuario.service';
import { ECargo } from 'src/app/usuario/models/cargo.enum';
import { DigitalDocsService } from 'src/app/services/digital-docs.service';
import { IEmailVO } from '../models/email-vo';
import { ICodigoVO } from '../models/codigo-vo';
import { ICodigoDataDTO } from '../models/codigo-data-dto';
import { ICodigoDTO } from '../models/codigo-dto';
import { IPasswordVO } from '../models/password-vo';
import { IPasswordDTO } from '../models/password-dto';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class AuthService extends DigitalDocsService{
  constructor(private http: HttpClient,
    private tokenService: TokenService,
    private infoUsuarioService: InfoUsuarioService,
    private router: Router) {
      super('solicitudregistro/usuario', http);
     }

  login(data: any): Observable<boolean> {
    const httpHeaders = new HttpHeaders({
      'Authorization': 'Basic aW5mbGFiOmluZmxhYg==',
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', data.username);
    params.set('password', data.password);
    return this.http.post<any>(`${API_URL}api/auth/login`, params.toString(), { headers: httpHeaders })
      .pipe(map((response) => {
        this.tokenService.setAccessToken(response.access_token);
        this.tokenService.setRefreshToken(response.refresh_token);
        this.redirigir();
        return true;
      }));
  }

  loginout(): void {
    this.tokenService.removeAccessToken();
    this.tokenService.removeRefreshToken();
    this.router.navigate(['home/autentificacion/login']);
  }

  redirigir(): void {
    this.infoUsuarioService.getMiCargo().subscribe(resp => {
      resp.filter(cargo => {       
        if (cargo.id === ECargo.TUTOR_ESTUDIANTE || 
            cargo.id === ECargo.DOCENTE_MATERIA  || 
            cargo.id === ECargo.DOCENTE_REGULAR  || 
            cargo.id === ECargo.RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO) {
          this.router.navigate(['/perfiles/lista/registros']);
        } else if ( cargo.id === ECargo.ESTUDIANTE || 
                    cargo.id === ECargo.POSTULANTE_AUXILIATURA){
          this.router.navigate(['/perfiles/mi-perfil']);
        } else if (cargo.id === ECargo.TRIBUNAL) {
          this.router.navigate(['/defensas/lista']);
        } else {
          this.router.navigate(['/documento']);
        }
      });
    });
  }
  enviarEmail(email:IEmailVO):Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Authorization': 'Basic aW5mbGFiOmluZmxhYg==',
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post<any>(this.url+ `/recuperar/${email.email}`,{}).pipe(
      map((resp) => {
        return resp['data'] as any;
      },(err)=>{
        return err;         
      })
    );
  }
  enviarCodigo(codigoVO:ICodigoVO):Observable<ICodigoDataDTO> {
      return this.http.post<ICodigoDTO>(this.url+ '/codigo', codigoVO).pipe(
      map(resp => {
        return resp['data'] as ICodigoDataDTO;
      })
    );
  }
  public editUsuarioContrasenia(emailVO: IEmailVO, usuarioPassword: IPasswordVO): Observable<IPasswordDTO> {
    return this.http.put(this.url+ `/editar/${emailVO.email}` , usuarioPassword).pipe(
      map((resp) => {
        return resp['data'] as IPasswordDTO;
      },(err)=>{
        return err;         
      })
    );
  }
}
