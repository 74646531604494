import { RouteInfo } from './route-info';

const ROUTER: any = [
  // 0 : USUARIOS
  {
    path: '/usuarios/lista',
    title: 'USUARIOS',
    icon: 'mdi mdi-account-multiple',
    class: '',
    prioridad: 1,
    subRuta: []
  },
  // 1 : DOCUMENTOS
  {
    path: '/documento',
    title: 'DOCUMENTOS',
    icon: 'nc-paper',
    class: '',
    prioridad: 1,
    subRuta: []
  },
  // 2 : PERFILES, * Prioridad 1
  {
    path: '',
    title: 'PERFILES',
    icon: 'mdi mdi-note-multiple',
    class: '',
    isCollapsed: true,
    prioridad: 1,
    subRuta: [
      { path: '/perfiles/lista/registros', title: 'Lista de Perfiles', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/perfiles/lista/cambios', title: 'Lista Cambios en el perfil', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
    ]
  },
  // 3 : DEFENSA DE PROYECTO, * Prioridad  2
  {
    path: '',
    title: 'DEFENSAS DE PROYECTO',
    icon: 'mdi mdi-projector-screen',
    class: '',
    isCollapsed: true,
    prioridad: 2,
    subRuta: [
      { path: '/defensas/registro', title: 'Registrar Defensas de proyecto', icon: 'mdi mdi-plus-circle', class: '', subRuta: [] },
      { path: '/defensas/lista', title: 'Listar Defensas de proyecto', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/defensas/docentes', title: 'Listar Docentes asignados Defensa', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
    ]
  },
  // 4 : DEFENSA DE PROYECTO, * P 1
  {
    path: '/defensas/lista',
    title: 'DEFENSAS DE PROYECTO',
    icon: 'mdi mdi-projector-screen',
    class: '',
    prioridad: 1,
    subRuta: []
  },
  // 5 : RESOLUCIONES
  {
    path: '',
    title: 'RESOLUCIONES',
    icon: 'mdi mdi-note-multiple',
    class: '',
    isCollapsed: true,
    prioridad: 1,
    subRuta: [
      { path: '/resolucion/listaResolFacultativa', title: 'Lista facultativa', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/resolucion/listaResolUniversitaria', title: 'Lista universitaria', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/resolucion/listaCarrera', title: 'Lista carrera', icon: 'mdi mdi-format-list-bulleted', class: 'mdi mdi-format-list-bulleted', subRuta: [] },
      {
        path: '', title: 'Historial', icon: 'mdi mdi-format-list-bulleted', class: '', isCollapsed: true, subRuta: [
          { path: '/resolucion/historialResoluciones/carrera', title: 'Carrera', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
          { path: '/resolucion/historialResoluciones/facultativo', title: 'Facultativo', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
          { path: '/resolucion/historialResoluciones/universitario', title: 'Universitario', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },

        ]
      },

    ]
  },
  // 6 : MI PERFIL
  {
    path: '/perfiles/mi-perfil',
    title: 'MI PERFIL',
    icon: 'mdi mdi-file-document',
    class: '',
    prioridad: 1,
    subRuta: []
  },
  // 7 : OFERTA DOCENTE
  {
    path: '',
    title: 'OFERTA DOCENTE',
    icon: 'mdi mdi-account-multiple',
    class: '',
    isCollapsed: true,
    prioridad: 1,
    subRuta: [
      { path: '/oferta-docente/diplomas', title: 'Lista de Diplomas', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/oferta-docente/materias', title: 'Lista de Materias', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/oferta-docente/docentes', title: 'Lista de Docentes', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/oferta-docente/observaciones', title: 'Lista de Observaciones', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/oferta-docente/estados', title: 'Lista de Estados', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/oferta-docente/materia-carrera', title: 'Lista de materia carrera', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/oferta-docente/', title: 'Lista de Oferta Docente', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] }
    ]
  },
  // 8 : GESTION DEL SISTEMA
  {
    path: '',
    title: 'GESTION DEL SISTEMA',
    icon: 'mdi mdi-settings',
    class: '',
    isCollapsed: true,
    prioridad: 1,
    subRuta: [
      { path: '/gestion-sistema/gestiones', title: 'Gestiones', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/gestion-sistema/areas-subareas', title: 'Areas y SubAreas Perfil', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
    ]
  },
  // 9 : PERFILES,* P 2
  {
    path: '',
    title: 'PERFILES',
    icon: 'mdi mdi-note-multiple',
    class: '',
    isCollapsed: true,
    prioridad: 2,
    subRuta: [
      { path: '/perfiles/lista/registros', title: 'Lista de Perfiles', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/perfiles/lista/cambios', title: 'Lista Cambios en el perfil', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/perfiles/lista-para-revision', title: 'Lista para Revision de Perfiles', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      //{ path: '/reporte/reporte-perfiles', title: 'reporte perfil', icon: 'mdi mdi-chart-areaspline', class: '', subRuta: [] },
    ]
  },
  // 10: REPORTE GRAFICOS
  {
    path: '',
    title: 'REPORTES',
    icon: 'mdi mdi-chart-histogram',
    class: '',
    isCollapsed: true,
    prioridad: 1,
    subRuta: [
      { path: '/reporte/reporte-perfiles', title: 'reporte perfil', icon: 'mdi mdi-chart-areaspline', class: '', subRuta: [] },
    ]
  },
  // 11 : CONVALIDACIONES, P 1
  {
    path: '',
    title: 'CONVALIDACIONES',
    icon: 'mdi mdi-equal-box',
    class: '',
    isCollapsed: true,
    prioridad: 1,
    subRuta: [
      { path: '/convalidacion/materia-carrea', title: 'Establecer materia a carrera', icon: 'mdi mdi-library-plus', class: '', subRuta: [] },
      { path: '/convalidacion/universidad-origen', title: 'Datos universidad origen', icon: 'mdi mdi-database', class: '', subRuta: [] },
      { path: '/convalidacion/lista-estudiantes', title: 'Lista de estudiantes', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
      { path: '/convalidacion/convalidar-materias', title: 'Convalidar Materias', icon: 'mdi mdi-content-copy', class: '', subRuta: [] },
      { path: '/convalidacion/informe-convalidaciones', title: 'Informe Convalidaciones', icon: 'mdi mdi-file-document', class: '', subRuta: [] },
    ]
  },
  // 12 : MODULO AUXILIATURA - CARGO : ADMINISTRADOR - DIR DE CARRERA
  {
    path: '',
    title: 'CONVOCATORIA AUXILIATURA',
    icon: 'mdi mdi-projector-screen',
    class: '',
    isCollapsed: true,
    prioridad: 2,
    subRuta: [
      {
        path: '', title: 'Convocatorias', icon: 'mdi mdi-file-document-box', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/crear-convocatoria', title: 'Crear Convocatoria', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/crear-items-convocatoria', title: 'Crear Items', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/tipos-evaluacion-convocatoria', title: 'Tipos de evaluacion', icon: 'mdi mdi-clipboard-text', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-docentes', title: 'Docentes asignados', icon: 'mdi mdi-account-check', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Postulantes', icon: 'mdi mdi-account-multiple', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/lista-postulantes', title: 'Lista Postulantes', icon: 'mdi mdi-account-multiple-outline', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/registro-postulante', title: 'Registrar Postulante', icon: 'mdi mdi-account-card-details', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-postulante-items', title: 'Reporte Postulante items', icon: 'mdi mdi-file-check', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-item-postulantes', title: 'Reporte Item postulantes', icon: 'mdi mdi-file-document', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/postulantes-habiliatados-inhabilitados', title: 'Habilitados e Inhabilitados', icon: 'mdi mdi-account-check', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Requisitos', icon: 'mdi mdi-format-align-justify', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/registro-requisitos', title: 'Crear Requisitos', icon: 'mdi mdi-format-list-numbers', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/evaluar-requisitos', title: 'Evaluar Requisitos', icon: 'mdi mdi-file-check', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-requisitos', title: 'Reporte Requisitos', icon: 'mdi mdi-file-excel', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Documentos', icon: 'mdi mdi-folder-multiple', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/crear-documentos-presentar', title: 'Crear Documentos', icon: 'mdi mdi-file-multiple', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/evaluar-documentos', title: 'Evaluar Documentos', icon: 'mdi mdi-file-check', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-documentos', title: 'Reporte Documentos', icon: 'mdi mdi-file-chart', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Meritos', icon: 'mdi mdi-file-check', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/crear-meritos', title: 'Crear Meritos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/evaluar-meritos', title: 'Evaluar Meritos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-meritos', title: 'Reporte Meritos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Conocimientos', icon: 'mdi mdi-lightbulb-on', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/calificacion-conocimientos', title: 'Crear Calificaciones', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/lista-conocimientos', title: 'Rep. de porcentaje establecido', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/evaluar-conocimientos', title: 'Evaluar conocimientos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-conocimientos', title: 'Reporte conocimientos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
        ]
      },
      { path: '/modulo-auxiliatura/reporte-resultados-finales', title: 'Reporte resultados finales', icon: 'mdi mdi-file-chart', class: '', subRuta: [] },
      { path: '/modulo-auxiliatura/configuraciones', title: 'Configuraciones', icon: 'mdi mdi-settings', class: '', subRuta: [] },
    ]
  },
  // 13 : MODULO AUXILIATURA - CARGO : SECRETARIA
  {
    path: '',
    title: 'CONVOCATORIA AUXILIATURA',
    icon: 'mdi mdi-projector-screen',
    class: '',
    isCollapsed: true,
    prioridad: 1,
    subRuta: [
      {
        path: '', title: 'Convocatorias', icon: 'mdi mdi-file-document-box', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/crear-convocatoria', title: 'Crear Convocatoria', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/crear-items-convocatoria', title: 'Crear Items', icon: 'mdi mdi-format-list-bulleted', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/tipos-evaluacion-convocatoria', title: 'Tipos de evaluacion', icon: 'mdi mdi-clipboard-text', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-docentes', title: 'Docentes asignados', icon: 'mdi mdi-account-check', class: '', subRuta: [] },
        ]
      },

      {
        path: '', title: 'Postulantes', icon: 'mdi mdi-account-multiple', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/lista-postulantes', title: 'Lista Postulantes', icon: 'mdi mdi-account-multiple-outline', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/registro-postulante', title: 'Registrar Postulante', icon: 'mdi mdi-account-card-details', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-postulante-items', title: 'Reporte Postulante items', icon: 'mdi mdi-file-check', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-item-postulantes', title: 'Reporte Item postulantes', icon: 'mdi mdi-file-document', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/postulantes-habiliatados-inhabilitados', title: 'Habilitados e Inhabilitados', icon: 'mdi mdi-account-check', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Requisitos', icon: 'mdi mdi-format-align-justify', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/registro-requisitos', title: 'Crear Requisitos', icon: 'mdi mdi-format-list-numbers', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-requisitos', title: 'Reporte Requisitos', icon: 'mdi mdi-file-excel', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Documentos', icon: 'mdi mdi-folder-multiple', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/crear-documentos-presentar', title: 'Crear Documentos', icon: 'mdi mdi-file-multiple', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-documentos', title: 'Reporte Documentos', icon: 'mdi mdi-file-chart', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Meritos', icon: 'mdi mdi-file-check', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/crear-meritos', title: 'Crear Meritos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-meritos', title: 'Reporte Meritos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Conocimientos', icon: 'mdi mdi-lightbulb-on', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/calificacion-conocimientos', title: 'Crear Calificaciones', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/lista-conocimientos', title: 'Lista Conocimientos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-conocimientos', title: 'Reporte conocimientos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
        ]
      },
      { path: '/modulo-auxiliatura/reporte-resultados-finales', title: 'Reporte resultados finales', icon: 'mdi mdi-file-chart', class: '', subRuta: [] },
      { path: '/modulo-auxiliatura/configuraciones', title: 'Configuraciones', icon: 'mdi mdi-settings', class: '', subRuta: [] },
    ]
  },
  // 14 : MODULO AUXILIATURA - CARGO : DOCENTE DE MATERIA
  {
    path: '',
    title: 'CONVOCATORIA AUXILIATURA',
    icon: 'mdi mdi-projector-screen',
    class: '',
    isCollapsed: true,
    prioridad: 1,
    subRuta: [
      {
        path: '', title: 'Postulantes', icon: 'mdi mdi-account-multiple', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/lista-postulantes', title: 'Lista Postulantes', icon: 'mdi mdi-account-multiple-outline', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/postulantes-habiliatados-inhabilitados', title: 'Habilitados e Inhabilitados', icon: 'mdi mdi-account-check', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Requisitos', icon: 'mdi mdi-format-align-justify', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/evaluar-requisitos', title: 'Evaluar Requisitos', icon: 'mdi mdi-file-check', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-requisitos', title: 'Reporte Requisitos', icon: 'mdi mdi-file-excel', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Documentos', icon: 'mdi mdi-folder-multiple', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/evaluar-documentos', title: 'Evaluar Documentos', icon: 'mdi mdi-file-check', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-documentos', title: 'Reporte Documentos', icon: 'mdi mdi-file-chart', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Meritos', icon: 'mdi mdi-file-check', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/evaluar-meritos', title: 'Evaluar Meritos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-meritos', title: 'Reporte Meritos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
        ]
      },
      {
        path: '', title: 'Conocimientos', icon: 'mdi mdi-lightbulb-on', class: '', isCollapsed: true, subRuta: [
          { path: '/modulo-auxiliatura/lista-conocimientos', title: 'Rep. de porcentaje establecido', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/evaluar-conocimientos', title: 'Evaluar conocimientos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
          { path: '/modulo-auxiliatura/reporte-conocimientos', title: 'Reporte conocimientos', icon: 'mdi mdi-file-document-box', class: '', subRuta: [] },
        ]
      },
    ]
  },
  // 15 : MODULO AUXILIATURA - CARGO : POSTULANTE A AUXILIATURA
  {
    path: '',
    title: 'CONVOCATORIA AUXILIATURA',
    icon: 'mdi mdi-projector-screen',
    class: '',
    isCollapsed: true,
    prioridad: 2,
    subRuta: [
      { path: '/modulo-auxiliatura/registrarme-como-postulante', title: 'Postularme a una convocatoria', icon: 'mdi mdi-account-plus', class: '', subRuta: [] },
      { path: '/modulo-auxiliatura/resultados-postulante-convocatoria', title: 'Resultados postulacion', icon: 'mdi mdi-checkbox-multiple-marked', class: '', subRuta: [] },
    ]
  },
];
export const ROUTES_ADMIN: RouteInfo[] = [
  ROUTER[0],
  ROUTER[1],
  ROUTER[9],
  ROUTER[3],
  ROUTER[5],
  ROUTER[7],
  ROUTER[8],
  ROUTER[10],
  ROUTER[11],
  ROUTER[12],
];
export const ROUTES_DIRECTOR: RouteInfo[] = [
  ROUTER[1],
  ROUTER[2],
  ROUTER[4],
  ROUTER[10],
  ROUTER[12],
];
export const ROUTES_DOCENTE: RouteInfo[] = [
  ROUTER[2],
  ROUTER[14],
];
export const ROUTES_TUTOR: RouteInfo[] = [
  ROUTER[2]
];
export const ROUTES_TRIBUNAL: RouteInfo[] = [
  ROUTER[4]
];
export const ROUTES_ESTUDIANTE: RouteInfo[] = [
  ROUTER[6]
];
export const ROUTES_POSTULANTE_AUXILIATURA: RouteInfo[] = [
  ROUTER[15]
];
export const ROUTES_SECRETARIA: RouteInfo[] = [
  ROUTER[1],
  ROUTER[3],
  ROUTER[5],
  ROUTER[7],
  ROUTER[8],
  ROUTER[13],
];
export const ROUTES_CONSEJERO: RouteInfo[] = [
  ROUTER[5]
];
export const ROUTES_RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO: RouteInfo[] = [
  ROUTER[2]
];
export const ROUTES_DOCENTE_MATERIA: RouteInfo[] = [
  ROUTER[2]
];
