import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ROUTES_ADMIN } from 'src/app/sidebar/menu-items';
import { AuthService } from 'src/app/autentificacion/services/auth.service';
import { InfoUsuarioService } from 'src/app/autentificacion/services/info-usuario.service';
import { IUsuarioDTO } from 'src/app/usuario/models/usuario-dto';
import { ToastrService } from 'ngx-toastr';
import { ECargo } from 'src/app/usuario/models/cargo.enum';
import { ICargoUserDTO } from 'src/app/usuario/models/cargo-user-dto';
import { UsuariosService } from 'src/app/modulo-auxiliatura/services/usuarios.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  aux: any = null;
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;

  public isCollapsed = true;

  myUsuario: IUsuarioDTO = this.aux;
  idUsuarioAutenticado: number = this.aux;
  banderaEsEstudianteYNoPostulanteAun: boolean = false;


  @ViewChild("navbar-cmp", { static: false }) button;

  constructor(location: Location,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private element: ElementRef,
    private router: Router,
    private authService: AuthService,
    private infoUsuarioService: InfoUsuarioService,
    private usuariosService: UsuariosService) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.getMiUsuario();
    this.listTitles = ROUTES_ADMIN.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  cerrarSesion() {
    this.authService.loginout();
  }

  public mostrarModalConfirmacion(modalConfirmacion: any) {
    this.modalService.open(modalConfirmacion);
  }

  public postularAConvocatoria() {
    this.usuariosService.crearUsuarioPostulante(this.idUsuarioAutenticado).subscribe(
      (resp) => {
        this.mensajeCorrecto('Se guardo correctamente su registro', 'Exito: ');
        this.modalService.dismissAll();
        window.location.reload();
      }, (err) => {
        this.mensajeError(err.message, 'Error: ');
      }
    );
  }

  private tieneCargo(cargos: ICargoUserDTO[], cargo: ECargo) {
    let encontrado: boolean = false;
    let i: number = 0;
    while (!encontrado && i < cargos.length) {
      if (cargos[i].id === cargo) {
        encontrado = true;
      }
      i++;
    }
    return encontrado;
  }

  private mensajeError(mensaje: string, titulo?: string) {
    this.toastr.error(mensaje, titulo, {
      timeOut: 5000,
    });
  }

  private mensajeCorrecto(mensaje: string, titulo?: string) {
    this.toastr.success(mensaje, titulo, {
      timeOut: 2500,
    });
  }

  // consumo API-REST
  public getMiUsuario() {
    this.infoUsuarioService.getMiUsuario().subscribe(
      (resp) => {
        this.myUsuario = resp;
        this.idUsuarioAutenticado = this.myUsuario.id;
        this.banderaEsEstudianteYNoPostulanteAun = this.tieneCargo(this.myUsuario.cargos, ECargo.ESTUDIANTE) &&
          !this.tieneCargo(this.myUsuario.cargos, ECargo.POSTULANTE_AUXILIATURA);
      }, (err) => {
        this.mensajeError('No se puedo obtener el usuario autenticado')
      }
    );
  }
}
