export enum ECargo {
  DIRECTOR_CARRERA = 'DIRECTOR_CARRERA',
  DOCENTE_MATERIA = 'DOCENTE_MATERIA',
  DOCENTE_REGULAR = 'DOCENTE_REGULAR',
  TUTOR_ESTUDIANTE = 'TUTOR_ESTUDIANTE',
  ADMINISTRADOR = 'ADMINISTRADOR',
  ESTUDIANTE = 'ESTUDIANTE',
  CONSEJERO = 'CONSEJERO',
  SECRETARIA = 'SECRETARIA',
  TRIBUNAL = 'TRIBUNAL',
  RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO = 'RESPONSABLE_ADSCRIPCION_TRABAJO_DIRIGIDO',
  POSTULANTE_AUXILIATURA = 'POSTULANTE_AUXILIATURA',
}
