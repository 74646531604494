import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpinerPawComponent } from './spiner-paw.component';



@NgModule({
  declarations: [SpinerPawComponent],
  imports: [
    CommonModule
  ], exports: [SpinerPawComponent]
})
export class SpinerPawModule { }
 