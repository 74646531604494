import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  getAccessToken(): string {
    let token;
    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token');
    }
    return token;
  }

  setAccessToken(token: string): void {
    localStorage.setItem('token', token);
  }

  removeAccessToken(): void {
    localStorage.removeItem('token');
  }

  getRefreshToken(): string {
    let token;
    if (localStorage.getItem('refreshToken')) {
      token = localStorage.getItem('refreshToken');
    }
    return token;
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem('refreshToken', refreshToken);
  }

  removeRefreshToken(): void {
    localStorage.removeItem('refreshToken');
  }

}
